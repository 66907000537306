<template>
  <div class="fangtan">
    <!-- 导航 -->

    <div class="mainWidth">
      <!-- 面包屑 -->
      <el-row type="flex" justify="center" class="crumbs">
        <el-col>
          <el-breadcrumb separator=">">
            <el-breadcrumb-item :to="{ path: '/' }"
              >社科视频首页</el-breadcrumb-item
            >
            <el-breadcrumb-item>{{ fubiao }}</el-breadcrumb-item>
          </el-breadcrumb>
        </el-col>
      </el-row>

      <div class="container">
        <el-row type="flex" justify="center">
          <el-col>
            <!-- 副标题 -->
            <ViceHeadline :title="fubiao" />

            <el-row :gutter="$store.state.isEquipment == 'pc' ? 20 : 0">
              <el-col
                :xs="24"
                :sm="6"
                :md="6"
                :lg="6"
                :xl="6"
                v-for="item in list"
                :key="item.index"
              >
                <router-link
                  target="_blank"
                  :to="{
                    path: '/xq',
                    query: {
                      qing: item.id,
                      mianfu: fubiao,
                    },
                  }"
                >
                  <div class="card">
                    <div class="coverWrap">
                      <img
                        v-lazy="item.pub_cover"
                        alt=""
                        class="cover"
                        :class="{ bookCover: fubiao === '好书' }"
                      />
                      <div class="duration">
                        <p>{{ item.TIME_LENGTH }}</p>
                      </div>
                      <img
                        src="@/assets/image/zixun/播放.png"
                        alt=""
                        class="pause"
                      />
                    </div>
                    <div class="card_content">
                      <p class="title">{{ item.SYS_TOPIC }}</p>
                      <p class="time">
                        {{ $ModuleDate(item.RECORD_TIME) }}
                      </p>
                    </div>
                  </div>
                </router-link>
              </el-col>
            </el-row>
            <Paging :value="value" @transfer="getData" />
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
import NavType from "@/components/NavType.vue";
import ViceHeadline from "@/components/ListHeadline.vue";
import Paging from "@/components/Paging.vue";

export default {
  name: "IndexList",
  props: {
    msg: String,
  },
  components: {
    ViceHeadline,
    NavType,
    Paging,
  },
  data() {
    return {
      mianbao: "",
      fubiao: "",
      fubiaou: "",
      list: [],
      value: {
        path: this.$route.query.id + "",
        amount: "9",
      },
    };
  },
  created() {
    this.getTitle(this.$route.query.id);
    // if(this.$route.query.mian == 'oozi')
  },
  methods: {
    getTitle(val) {
      this.path = val;
      switch (this.$route.query.id) {
        case "50":
          this.fubiao = "资讯";
          this.fubiaou = "/zixun";
          break;
        case "51":
          this.fubiao = "访谈";
          this.fubiaou = "/fangtan";
          break;
        case "65":
          this.fubiao = "专题";
          this.fubiaou = "/zhaunti";
          break;
        case "66":
          this.fubiao = "成果";
          this.value.amount = 12;
          break;
        case "67":
          this.fubiao = "讲坛";
          this.value.amount = 12;
          break;
        case "68":
          this.fubiao = "纪实";
          this.value.amount = 12;
          break;
        case "69":
          this.fubiao = "好书";
          this.value.amount = 12;
          break;
        default:
          break;
      }
    },

    getData(msg) {
      this.list = msg;
    },
  },
};
</script>
<style scoped lang="scss">
h3 {
  margin: 2.5rem 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 0.625rem;
}

a {
  color: #42b983;
}

.fangtan {
  background: #f9f9f9;

  .crumbs {
    padding-top: 1.25rem;
  }

  .container {
    .card {
      margin-top: 1.5rem;
      background: #ffffff;
      box-shadow: 0px 0px 13px 0px rgba(183, 183, 183, 0.38);
      position: relative;
      cursor: pointer;

      .duration {
        position: absolute;
        bottom: 1rem;
        right: 1rem;

        p {
          font-size: 1rem;
          font-weight: 400;
          color: #ffffff;
          line-height: normal;
          text-align: right;
          white-space: nowrap;
        }
      }

      .coverWrap {
        position: relative;
      }
      .cover {
        display: block;
        aspect-ratio: 4/3;
        width: 100%;
        height: 251px;
      }
      .bookCover {
        aspect-ratio: 1/1;
        width: 100%;
        display: block;
        height: 335px;
      }
      @media screen and(max-width:1440px) {
        .bookCover{
          height: 235px !important;
        }
      }
      .pause {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 4rem;
      }

      .card_content {
        padding: 1rem 1.5rem;

        .title {
          font-size: 1.25rem;
          font-weight: bold;
          color: #212121;
          line-height: 2.25rem;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .time {
          font-size: 1rem;
          font-weight: 400;
          color: #666666;
          line-height: 2rem;
          height:2rem;
        }
      }
    }
  }
}
@media screen and(max-width:1440px) {
  .fangtan .container .card .cover {
    height: 176px;
  }
  .pc .fangtan .container .card .cover {
    height: auto;
  }
}
</style>