<template>
  <div
    class="homeList_phone"
    v-infinite-scroll="getData"
    infinite-scroll-distance="10"
    infinite-scroll-disabled="busy"
  >
    <!-- 导航 -->
    

    <!-- 面包屑 -->
    <el-row type="flex" justify="center" class="crumbs">
      <el-col >
        <el-breadcrumb separator=">">
          <el-breadcrumb-item :to="{ path: '/' }">社科视频首页</el-breadcrumb-item>
          <el-breadcrumb-item>{{ fubiao }}</el-breadcrumb-item>
        </el-breadcrumb>
      </el-col>
    </el-row>

    <div class="container">
      <el-row type="flex" justify="center">
        <el-col >
          <!-- 副标题 -->
          <ViceHeadline :title="fubiao" />

          <el-row :gutter="$store.state.isEquipment == 'pc'?40:0">
            <el-col
              :xs="24"
              :sm="24"
              :md="6"
              :lg="6"
              :xl="6"
              v-for="item in list"
              :key="item.index"
            >
              <router-link target="_blank"
                :to="{ path: '/xq', query: { qing: item.id,mian:`pho${fubiao}` } }"
              >
                <div class="card">
                  <div class="coverWrap">
                      <img  v-lazy="item.pub_cover" alt="" class="cover" />
                      <div class="duration">
                        <p>{{ item.TIME_LENGTH }}</p>
                      </div>
                      <img
                        src="@/assets/image/zixun/播放.png"
                        alt=""
                        class="pause"
                      />
                    </div>
                  <div class="card_content">
                    <p class="title">{{ item.SYS_TOPIC }}</p>
                    <p class="time">
                      {{ $ModuleDate(item.RECORD_TIME) }}
                    </p>
                  </div>
                </div>
              </router-link>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
    </div>

    <div class="loading">
      <p class="busy" v-show="busy">加载中...</p>
      <p class="finish" v-show="finish">没有更多了</p>
    </div>
  </div>
</template>

<script>
import NavType from "@/components/NavType.vue";
import ViceHeadline from "@/components/ListHeadline.vue";
import api from "@/api/index.js";
export default {
  props: {
    msg: String,
  },
  components: {
    ViceHeadline,
    NavType,
  },
  data () {
    return {
      fubiao: "访谈",
      pageNo: 1,
      busy: false,
      finish: false,
      disabled: false,
      list: [],
    };
  },
  watch: {
    $route: {
      handler () {
        this.load();
      },
    },
    $route (to) {
      //监听路由变化的时候使滚动条回到顶部
      document.getElementById("fangtan_phone").scrollTo(0, 0);
    },
  },
  created () {
    this.getTitle(this.$route.query.id)
  },
  methods: {
    load () {
      var slef = this;
      if (this.pageNo == 1) {
        slef.getData();
      } else {
        this.busy = true;
        setTimeout(function () {
          slef.getData();
        }, 1000);
      }
    },
    getData () {
      if(this.finish){
        return;
      }
      this.busy= true;
      api
        .post("spc/cms/publish/queryList.do", {
          s: 2,
          c: this.$route.query.id,
          pageNo: this.pageNo,
          pageSize: 9,
        })
        .then((res) => {
          for (var item in res.result) {
            this.list.push(res.result[item]);
          }
          this.busy = false;
          if (res.result.length == 0) {
            this.finish = true;
          } else {
            this.pageNo += 1;
          }
        });
    },
    getTitle (val) {
      this.path = val;
      switch (this.$route.query.id) {
        case "50":
          this.fubiao = "资讯";
          break;
        case "51":
          this.fubiao = "访谈";
          break;
        case "66":
          this.fubiao = "成果";
          break;
        case "67":
          this.fubiao = "讲坛";
          break;
        case "68":
          this.fubiao = "纪实";
          break;
        case "69":
          this.fubiao = "好书";
          break;
        default:
          break;
      }
    },
  },
};
</script>
<style scoped lang="scss">
h3 {
  margin: 2.5rem 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 .625rem;
}

a {
  color: #42b983;
}

.homeList_phone {
  background: #f9f9f9;
  padding:0 0.5rem;

  .crumbs {
    padding-top: 1.25rem;
  }

  .container {
    .card {
      margin-top: 1.5rem;
      background: #ffffff;
      box-shadow: 0px 0px 13px 0px rgba(183, 183, 183, 0.38);
      position: relative;
      cursor: pointer;

      .cover {
        width: 100%;
        // height: 15.5625rem;
      }

      .duration {
        position: absolute;
        right:1rem;
        bottom:1rem;
        p {
          font-size: 1rem;
          font-weight: 400;
          color: #ffffff;
          line-height: normal;
          text-align: right;
          white-space: nowrap;
        }
      }

      .coverWrap {
          position: relative;
        }
        .pause {
          position: absolute;
          left: 0;
          bottom: 0;
          width: 6rem;
        }

      .card_content {
        padding: 1rem;

        .title {
          font-size: 1.25rem;
          font-weight: bold;
          color: #212121;
          line-height: 2.25rem;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .time {
          font-size: 1rem;
          font-weight: 400;
          color: #666666;
          line-height: 2rem;
          height:2rem;
        }
      }
    }
  }
  .loading {
    margin: 0.5rem 0;

    p {
      line-height: 2rem;
      font-size: 1rem;
      text-align: center;
      color: #5e6d82;
    }
  }
}
</style>
